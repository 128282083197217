export default function (Alpine) {
    Alpine.directive('fade', (el, { value, expression, modifiers }, { evaluateLater, cleanup }) => {
        let translate = getTranslate(modifiers);
        let delay = parseInt(modifierValue(modifiers, 'delay', 0)) + (50 * numFadingPreviousSiblings(el));
        let options = {
            threshold: getThreshhold(modifiers) === 0 ? 0 : [0, getThreshhold(modifiers)],
            rootMargin: '9999px 9999px 0px'
        }

        el.classList.add('transition', 'duration-500', 'transform', 'motion-safe:opacity-0', 'motion-reduce:!transform-none');

        let observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (
                    entry.intersectionRatio === 0
                    // && entry.boundingClientRect.top > 0
                    // && entry.boundingClientRect.x > 0
                    // && entry.boundingClientRect.x < entry.rootBounds.right
                ) {
                    el.classList.add('motion-safe:opacity-0', translate);
                    
                    return;
                }

                if (
                    entry.intersectionRatio < getThreshhold(modifiers)
                    // && entry.boundingClientRect.x > 0
                    // && entry.boundingClientRect.x < (entry.rootBounds.right - 100)
                )  return;

                setTimeout(() => {
                    el.classList.remove('opacity-0', 'motion-safe:opacity-0', translate);
                }, delay);
            });
        }, options);

        observer.observe(el);

        cleanup(() => {
            observer.disconnect();
        });
    });
};

function getThreshhold(modifiers) {
    if (modifiers.includes('half')) return 0.5;
    if (modifiers.includes('full')) return 0.99;

    return 0;
}

function getTranslate(modifiers) {
    if (modifiers.includes('opacity')) return 'translate-x-0';
    if (modifiers.includes('left')) return '-translate-x-12';
    if (modifiers.includes('right')) return 'translate-x-12';

    return 'translate-y-12';
}

function numFadingPreviousSiblings(elem) {
    if (elem.parentNode.children.length == 1) return 0;

    let num = 0;

    while (elem = elem.previousSibling) {
        if (elem.nodeType === 3) continue;
        if (! elem.hasAttribute('x-fade')) break;

        num++;
    }
    return num;
}

export function modifierValue(modifiers, key, fallback) {
    // If the modifier isn't present, use the default.
    if (modifiers.indexOf(key) === -1) return fallback

    const rawValue = modifiers[modifiers.indexOf(key) + 1]

    if (! rawValue) return fallback

    return rawValue
}