export default () => ({
  utm: Alpine.$persist({}).as('comtech_analytics'),

  setGFormsUTMCodes () {
    const trackingKeys = ['utm_source', 'utm_medium', 'utm_campaign'];
    let urlSearchParams = new URLSearchParams(window.location.search);
    let params = Object.fromEntries(urlSearchParams.entries());

    trackingKeys.forEach(key => {
      let value = '';
      let fields = document.querySelectorAll(`[data-analytics="${key}"]`);
      
      if (fields.length == 0) return;

      if (key in params) {
        value = params[key];
      }

      if (! this.utm.hasOwnProperty(key) || value.length > 0) {
        this.utm[key] = value;
      }

      fields.forEach(field => {
        field.value = this.utm[key];
      })
    });
  },

  setGFormsLanguageField () {
    let code = document.documentElement.lang

    const availableLanguageCodes = ['en-US', 'fr', 'es']
    const lang = new Intl.DisplayNames(availableLanguageCodes, { type: 'language' })
    const fields = document.querySelectorAll(`[data-lang]`)

    if (code.length == 0) {
      code = 'en-US'
    }

    fields.forEach(field => {
      field.value = lang.of(code)
    })
  },

  init() {
    this.setGFormsUTMCodes();
    this.setGFormsLanguageField();
  }
})