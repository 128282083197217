import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'
import intersect from '@alpinejs/intersect'
import persist from '@alpinejs/persist'
import fade from './../alpine-components/_fade.js'
import App from './../alpine-components/_App'


export default function(){
	Alpine.plugin(focus)
	Alpine.plugin(collapse)
	Alpine.plugin(intersect)
	Alpine.plugin(persist)
	Alpine.plugin(fade);
  Alpine.data('App', App)

	window.Alpine = Alpine
	Alpine.store('header', {
		init() {
			this.setNavStatus()
		},

		stuck: false,

		open: false,

		toggleable: false,

		toggleNav() {
			this.open = !this.open
		},

		setNavStatus() {
			this.toggleable = !window.matchMedia('(min-width: 768px)').matches;
			this.open = window.matchMedia('(min-width: 768px)').matches;
		}
	});

	Alpine.start()
}
