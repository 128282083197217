// import 'vite/modulepreload-polyfill'
// import alpineUI from '@alpinejs/ui'
import StartAlpine from './partials/_alpine'
import cookieStorage from './partials/_cookie-storage';
// import Player from '@vimeo/player'
// import Splide from '@splidejs/splide';

import '../css/app.css'

StartAlpine();


